import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GoogleMap, LoadScript } from "@react-google-maps/api"
import { Marker, InfoWindow } from "@react-google-maps/api"
const mapContainerStyle = {
  width: "100%",
  minHeight: "400px",
}

const locations = [
  {
    lat: 1.2903186,
    lng: 103.8130043,
  },
  {
    lat: 1.2907132,
    lng: 103.8101457,
  },
]
const divStyle = {
  background: `white`,
  padding: `3px`,
  //minHeight: `150px`,
}
const LocatePage = ({ data }) => {
  const [marker, setMarker] = useState("")

  return (
    <Layout header="nomenu">
      <div className="no-masthead relative"></div>

      <section className="section sectionFirst">
        <div className="container py-10">
          <h1 className="text-2xl lg:text-4xl font-light uppercase mb-6 md:mb-12">
            Locate Us.
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-12">
            <div className="md:col-span-5">
              <h2 className="mb-1 font-bold underline">BMW Showroom</h2>
              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">Eurokars Auto</h3>
                <p>11 Leng Kee Road</p>
                <p>Singapore 159091</p>
              </div>
              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">Opening Hours:</h3>
                <p>Monday to Saturday: 8:30am – 7:00pm</p>
                <p>Sunday and Public Holidays: 10:00am – 6:00pm</p>
              </div>
              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">You may also reach us by:</h3>
                <ul className="list-disc list-inside">
                  <li>
                    Phone: <a href="tel:+6562698833">6269 8833</a>
                  </li>
                  <li>
                    WhatsApp:{" "}
                    <a
                      href="https://api.whatsapp.com/send?phone=6583830866&text="
                      target={`_blank`}
                      rel={`noreferer`}
                    >
                      8383 0866
                    </a>
                  </li>
                  <li>
                    Submitting an online form{" "}
                    <Link to="/contact-us/" className="underline">
                      here
                    </Link>
                    .
                  </li>
                </ul>
              </div>
              <h2 className="mt-5 md:mt-8 mb-1 font-bold underline">
                Authorised BMW Service Centre
              </h2>
              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">Eurokars Auto</h3>
                <p>29 Leng Kee Road</p>
                <p>Singapore 159099</p>
              </div>
              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">Opening Hours:</h3>
                <h4 className="font-bold">BMW Service</h4>
                <p>Monday to Friday: 8:00am – 6:00pm</p>
                <p>Saturday: 8:00am – 12:00pm</p>
                <p>Sunday and Public Holidays: Closed</p>
              </div>
              <div className="mb-3 md:mb-5">
                <h4 className="font-bold">BMW Parts Counter</h4>
                <p>Monday to Thursday: 8:30am – 6:00pm</p>
                <p>Friday: 8:30am – 5:30pm</p>
                <p>Saturday: 8:30am – 12:30pm</p>
                <p>Sunday and Public Holidays: Closed</p>
              </div>
              <div className="mb-3 md:mb-5">
                <h3 className="font-bold">You may also reach us by:</h3>
                <ul className="list-disc list-inside">
                  <li>
                    Phone: <a href="tel:+6562198388">6219 8388</a>
                  </li>
                  <li>
                    WhatsApp:{" "}
                    <a
                      href="https://api.whatsapp.com/send?phone=6583830866&text="
                      target={`_blank`}
                      rel={`noreferer`}
                    >
                      8383 0866
                    </a>
                  </li>
                  <li>
                    Submitting an online form{" "}
                    <Link to="/contact-us/" className="underline">
                      here
                    </Link>
                    .
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:col-span-7">
              <LoadScript googleMapsApiKey="AIzaSyCeiEkZfeInSSxVcFQR9QSoPtfZtPfIvZ0">
                <GoogleMap
                  id="marker-example"
                  mapContainerStyle={mapContainerStyle}
                  zoom={16}
                  center={locations[0]}
                >
                  <Marker
                    position={locations[0]}
                    onClick={() => setMarker("0")}
                  />
                  <Marker
                    position={locations[1]}
                    onClick={() => setMarker("1")}
                  />

                  {marker === "0" && (
                    <InfoWindow
                      onCloseClick={() => setMarker("")}
                      position={locations[0]}
                    >
                      <div className="" style={divStyle}>
                        <p>
                          <span className="font-bold">Eurokars Auto</span>
                          <br />
                          <a
                            href="https://goo.gl/maps/dwaFUTkU4PFGku9j7"
                            className="underline"
                            target={`_blank`}
                          >
                            View location
                          </a>
                        </p>
                      </div>
                    </InfoWindow>
                  )}

                  {marker === "1" && (
                    <InfoWindow
                      onCloseClick={() => setMarker("")}
                      position={locations[1]}
                    >
                      <div className="" style={divStyle}>
                        <p>
                          <span className="font-bold">
                            Authorised BMW Service Centre
                          </span>
                          <br />
                          <a
                            href="https://goo.gl/maps/JMPF483S3mrEW3RR9"
                            className="underline"
                            target={`_blank`}
                          >
                            View location
                          </a>
                        </p>
                      </div>
                    </InfoWindow>
                  )}
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default LocatePage

const seoDetails = {
  title: "Eurokars Auto | Locate Us",
  description: "Locate our BMW Showroom and Authorised Service Centre.",
  keywords: "Eurokars, Eurokars Auto, BMW, BMW Eurokars Auto",
  image: "og-eka-locate-us.jpg",
}

export const Head = () => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-8FJZCNFV3R"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=GTM-M7DKTD9"
    ></script>
  </>
)
